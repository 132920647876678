import React from 'react'
import GalleryBackground from '../../components/galleryBackground'
import websiteRo from "../../images/rollovers/graphic-design/website-ro.png"
import "../layout.css"

export default function WebDesign() {
    return (
        <GalleryBackground title="Web Design" imgSrc={websiteRo}>
            <div className="columns-container">
                <a href="https://www.fayettebeerfest.com" target="_blank"><h2>Suds on the Square</h2></a>
                <a href="https://www.mynewphasefitness.com" target="_blank"><h2>New Phase Fitness</h2></a>
            </div>
        </GalleryBackground>
    )
}